import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

import { useHistory, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../utils';

import ConnectedDevices from './ConnectedDevices';
import Select from '../Components/Select';

const PATIENT_STATUSES = {
  0: 'Inactive',
  1: 'Active',
  2: 'Ununrolled',
  3: 'Graduated',
};

function calculateImperialBMI(patient) {
  // Weight should be in lbs
  const weight = parseFloat(patient.patient.patient_profile.weight_in_lbs);

  // Height should be in inches
  // Raw height is in feets like 17.5
  const rawHeight = patient.patient.patient_profile.height_in_ft;
  const ft = parseInt(rawHeight, 10);
  const inch = Math.round((rawHeight - ft) * 12);
  const height = ft * 12 + inch;

  const BMI = (weight / (height * height)) * 703;
  let roundedBMI = Math.floor(BMI);
  let diff = Math.round((BMI - roundedBMI) * 10);

  if (diff === 10) {
    roundedBMI += 1;
    diff = 0;
  }

  return `${roundedBMI}.${diff}`;
}

function AvatarUploader({ patientId, avatarURL }) {
  // You could pass an initial avatar URL as a prop if it already exists
  const [avatarUrl, setAvatarUrl] = React.useState(avatarURL || null);
  const fileInputRef = React.useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Optionally show a preview immediately (before uploading to the server)
    const previewUrl = URL.createObjectURL(file);
    setAvatarUrl(previewUrl);

    // Create form data
    const formData = new FormData();
    formData.append('avatar', file);
    console.log([patientId, formData]);

    // sendRequest(`users/${patientId}/avatar_change/`, 'POST', formData, true, true)
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.error('Error while uploading avatar:', error);
    //   });
  };

  return (
    <div>
      {/* Hidden file input (triggered by clicking on image/placeholder) */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Clickable area: either show the avatar or a placeholder box */}
      {avatarUrl ? (
        <button onClick={handleClick} type="button" className="btn-link">
          <img
            src={avatarUrl}
            alt="User Avatar"
            className="avatar-image"
          />
        </button>
      ) : (
        <button type="button" onClick={handleClick} className="btn-link upload-avatar-placeholder">
          Upload Picture
        </button>
      )}
    </div>
  );
}

AvatarUploader.propTypes = {
  patientId: PropTypes.string.isRequired,
  avatarURL: PropTypes.string,
};

AvatarUploader.defaultProps = {
  avatarURL: null,
};

const CommonInfo = ({ patient }) => {
  const history = useHistory();
  const { patientId } = useParams();
  const modalRemoveConnectionConfirm = React.useRef(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: patient, message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }
  });

  const handleStatusChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const { value } = e.target;

    sendRequest(`connections/patients/${patientId}/status_change`, 'PUT', { status: value })
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleHighRiskChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const value = e.target.checked;

    sendRequest(`connections/patients/${patientId}`, 'PUT', { needs_attention: value })
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleRemoveConnection = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${state.data.patient.id}`, 'DELETE')
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          history.replace('/');
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Common Info

        <a href={`/patients/${state.data.patient.id}/edit`} className="btn btn-small white black-text right hide-on-print">Edit</a>
        <button type="button" data-target="remove-modal" className="btn btn-small white red-text right hide-on-print mr2 modal-trigger">
          Remove
        </button>
      </h5>

      <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
      <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

      <div className="row">
        <AvatarUploader patientId={state.data.patient.id} avatarURL={state.data.patient.patient_profile.avatar} />
      </div>

      <div className="row">
        <div className="col s3">
          <h6>First Name</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.first_name}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Last Name</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.last_name || ''}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Status</h6>
        </div>
        <div className="col s9">
          <Select
            name="status"
            label="Status"
            values={PATIENT_STATUSES}
            defaultValue={patient.status}
            onChange={handleStatusChange}
          />
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Phone Number</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.phone_number}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Email</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.email}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Gender</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.gender === 'F' ? 'Female' : 'Male'}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Date of Birth</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.date_of_birth}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Height</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {parseFloat(state.data.patient.patient_profile.height_in_ft || '0.0').toFixed(2)}
              {' '}
              ft
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Weight</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {parseFloat(state.data.patient.patient_profile.weight_in_lbs || '0.0').toFixed(2)}
              {' '}
              lbs
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>BMI</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {`${calculateImperialBMI(patient)}`}
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Needs Attention</h6>
        </div>
        <div className="col s9">
          <div className="switch high-risk-switch">
            <label>
              No
              <input
                name="high_risk"
                type="checkbox"
                defaultChecked={state.data.patient.patient_profile.needs_attention}
                onChange={handleHighRiskChange}
              />
              <span className="lever" />
              Yes
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Chronic Conditions</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
                state.data.patient.patient_profile.chronic_conditions
                  ?.filter((cc) => cc !== '')
                  .map((cc) => (
                    <span key={cc} className="chip">
                      {cc}
                    </span>
                  ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Medication</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
              state.data.patient.patient_profile.medications
                ?.filter((m) => m !== '')
                .map((m) => (
                  <span key={m} className="chip">
                    {m}
                  </span>
                ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Conditions</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
                state.data.patient.patient_profile.conditions
                  ?.filter((c) => c !== '')
                  .map((c) => (
                    <span key={c} className="chip">
                      {c}
                    </span>
                  ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Emergency Contact</h6>
        </div>
        <div className="col s9">
          <h6>
            <dl>
              <strong>Name: </strong>
              {state.data.patient.patient_profile.emergency_contact_name}
              <br />

              <strong>Phone: </strong>
              {state.data.patient.patient_profile.emergency_contact_phone}
              <br />

              <strong>Email: </strong>
              {state.data.patient.patient_profile.emergency_contact_email}
              <br />

              <strong>Relationship: </strong>
              {state.data.patient.patient_profile.emergency_contact_relationship}
            </dl>
          </h6>
        </div>
      </div>

      <ConnectedDevices patientId={state.data.patient.id} />

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id="remove-modal" className="modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</a>
          <a href="#!" className="modal-close waves-effect btn-flat">No</a>
        </div>
      </div>

    </div>
  );
};

CommonInfo.propTypes = {
  patient: PropTypes.shape({
    status: PropTypes.number.isRequired,
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string,
      email: PropTypes.string.isRequired,
      patient_profile: PropTypes.shape({
        avatar: PropTypes.string,
        chronic_conditions: PropTypes.arrayOf(PropTypes.string),
        conditions: PropTypes.arrayOf(PropTypes.string),
        medications: PropTypes.arrayOf(PropTypes.string),
        surgeries_treatments: PropTypes.arrayOf(PropTypes.string),
        phone_number: PropTypes.string.isRequired,
        gender: PropTypes.string,
        height_in_ft: PropTypes.number.isRequired,
        weight_in_lbs: PropTypes.number.isRequired,
        date_of_birth: PropTypes.string,
        needs_attention: PropTypes.bool.isRequired,
        emergency_contact_name: PropTypes.string,
        emergency_contact_phone: PropTypes.string,
        emergency_contact_email: PropTypes.string,
        emergency_contact_relationship: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default CommonInfo;
